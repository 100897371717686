<!--
 * @Author: 张阳帅
 * @Date: 2024-09-23 17:52:37
 * @LastEditTime: 2024-11-21 14:42:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\sever\gjcRegister.vue
-->
<template>
  <div class="gcjregister_page">
    <div class="page_one">
      <div class="one_item">
        <div class="item_left">
          <div class="item_docName">
            {{ seleteDocDic.docName }} <a class="item_docTitle">{{ seleteDocDic.docTitle }}</a>
          </div>
          <div class="item_deptName">
            {{ seleteDocDic.deptName }}
          </div>
        </div>
        <div class="item_fee">
          ¥{{ seleteDateDic.fee }}
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          就诊日期
        </div>
        <div class="item_right">
          {{ seleteDateDic.dutyDate }}  {{ seleteDateDic.dutyTypeName }}
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          预约时段
        </div>
        <div class="item_right">
          {{ seleteTimeDic.timeBegin }} - {{ seleteTimeDic.timeEnd }}
        </div>
      </div>
    </div>
    <div class="page_two">
      <div class="one_item">
        <div class="item_left">
          就诊人
        </div>
        <div class="item_right">
          <div class="item_name">
            {{ patientDict.xm }} {{ patientDict.xbmc }} {{ patientDict.age }}岁
          </div>
          <div class="item_des">
            {{ patientDict.patientCardList[0].jzklxmc }} {{ patientDict.patientCardList[0].jzkh }}
          </div>
        </div>
      </div>
    </div>
    <div class="page_three">
      <div class="three_btn" @click="debounceGetCreateRegisterData">
        提交预约
      </div>
      <!-- <div class="three_des">
        温馨提示:在线支付需全自费支付诊查费用
      </div> -->
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant'
import {debounce} from '../../../utils/index'
import {gjcModel} from '../../../api/gjcApi'

export default {
    data(){
        return{
            patientDict:{}, //首页就诊人列表
            seleteTimeDic:{}, //选择时间
            seleteDateDic:{},//选择时间段
            seleteDocDic:{} //选择医生
        }
    },
    created(){
        this.seleteTimeDic = JSON.parse(this.$route.query.seleteTimeDic)
        this.seleteDateDic = JSON.parse(this.$route.query.seleteDateDic)
        this.seleteDocDic = JSON.parse(this.$route.query.seleteDocDic)
        this.patientDict = JSON.parse(this.$store.getters.patient)
    },
    mounted() {
    },
    methods:{
        //提交预约
        debounceGetCreateRegisterData:debounce(function(){
            this.getCreateRegisterData()
        }, 500,true),
        
        getCreateRegisterData(){
          Toast.loading({
            mask: true,
            duration: 0,
            message: "加载中..."
          })
            const params = {
                fee:this.seleteDateDic.fee, //挂号费
                fsjssj:this.seleteTimeDic.timeEnd, //号源时段 - 结束
                fskssj:this.seleteTimeDic.timeBegin, //号源时段 - 开始
                hyrq:this.seleteDateDic.dutyDate,//号源日期
                ksid:this.seleteDocDic.deptCode,//科室代码
                ksmc:this.seleteDocDic.deptName,//科室名称
                patientCardId:this.patientDict.patientCardList[0].patientCardId,//就诊卡ID
                patientInfoId:this.patientDict.patientId,//就诊人Id
                pbid:this.seleteTimeDic.resourceId,//排班号源Id
                sxwbz:this.seleteDateDic.dutyTypeCode,//上下午标志 1上午 2下午 3晚间
                ysdm:this.seleteDocDic.docCode,//医生代码
                ysxm:this.seleteDocDic.docName,//医生姓名
                yydm:this.seleteDocDic.hospCode,//机构代码
                yymc:this.seleteDocDic.hospName//机构名称
            }
            gjcModel.getCreateRegisterData(params).then(res=>{
              Toast.clear()
                console.log('首页就诊人列表', res.data)
                // this.patientDict = res.data
                // this.$toast('预约成功')
                // setTimeout(() => {
                    this.$router.push({
                        path: '/appointDetail',
                        query: {
                            id: res.data.appointRecordId
                        }
                    })
                // }, 500)
            }).catch((err) => {
              setTimeout(() => {
                Toast.clear() 
              }, 1000) 
              this.$toast(err.msg)
            })
        }
    }
}
</script>
<style scoped lang='less'>
.gcjregister_page{
    background-color: #f5f5f5;
    min-height: 100vh;
}
.page_one{
    background-color: white;
    border-bottom: 20px solid #f5f5f5;
}
.one_item{
    display: flex;
    justify-content: space-between;
    justify-items: center;
    padding: 20px 24px;
    border-bottom: 1px solid #f5f5f5;
    .item_left{
        font-size: 32px;
        color: #333333;

        .item_docName{
            font-weight: bold;

            .item_docTitle{
                margin-left: 24px;
                font-size: 24px;
                color: #666;
                font-weight: normal;
            }
        }
        .item_deptName{
            font-size: 24px;
            color: #999999;
        }
    }
    .item_fee{
        font-size: 32px;
        color: #f80404;
    }
    .item_right{
        text-align: right;
        font-size: 32px;
        color: #999999;
        .item_des{
            font-size: 26px;
        }
    }
}
.page_two{
    // display: flex;
    // justify-content: space-between;
    background-color: white;
    border-bottom: 20px solid #f5f5f5;
    .one_item{
        justify-items: center;
        padding: 20px 24px;
        border-bottom: 1px solid #f5f5f5;
        .item_left{
            font-size: 32px;
            color: #333333;
            line-height: 80px;
        }
    }
}
.page_three{
    .three_btn{
        margin: 40px 100px;
        padding: 20px;
        background-color: #1e80ff;
        border-radius: 10px;
        color: white;
        font-size: 32px;
        text-align: center;
    }

    .three_des{
        margin: 0 24px;
        text-align: center;
        font-size: 24px;
        color: #999;
    }
}
    
</style>
